@import 'src/assets/styles/globalStyles';

.erplay-page {
  height: 100%;
  // min-height: 85vh;
  background: $white;
  width: 100%;
  border-radius: 8px;
  padding: 31px 25px;

  .settings-wrapper {
    margin-top: 46px;
  }

  .edit-button {
    border: none;
    width: 100px;
    height: 36px;
    background: $main;
    border-radius: 8px;
    font: normal normal 600 14px/20px Open Sans;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-right: 8px;
      filter: brightness(0) invert(1);
    }
  }

  .edit-button:hover {
    background: $darkMain;
  }

  .line-inputs-wrapper {
    display: flex;
    align-items: center;
    margin-top: 16px;

    .inputs-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .inputs-wrapper-flex {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
    }
  }
}

.warehouses {
  margin-top: 24px;
  background: #ffffff;
  border-radius: 8px;
}

.warehouse-container {
  padding: 24px;

  .warehouse-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
  }

  .title-buttons-wrapper {
    .wrapper-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
    }
  }

  .wrapper-row {
    margin-top: 16px;

    .list-card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      background: #f7f9fc;
      border-radius: 4px;
      padding: 16px 20px;
    }

    .list-info {
      display: flex;
      align-items: center;
    }

    .list-action {
      display: flex;
      align-items: center;
    }

    .list-id {
      margin-right: 40px;
    }

    .list-name,
    .list-id {
      display: flex;
      align-items: center;

      .title {
        margin-right: 5px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #6a6d84;
      }

      .subtitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #6a6d84;
      }
    }
  }
}
