@import "src/assets/styles/globalStyles";

.logo-style {
  font-size: 20px;
  line-height: 26px;
  font-weight: 900;
  color: #FFFFFF;
}

.active-pending-style {
  width: 119px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font: normal normal 600 14px/19px Open Sans;
  }
}

.del-edit-wrapper {
  display: flex;

  button {
    background: none;
    border: none;
    width: 102px;
    padding: 9px 16px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    cursor: pointer;
    p {
      margin-left: 8px;
      font: normal normal 600 14px/19px Open Sans;
    }

    .edit-text {
      color: $darkMain;
    }

    .delete-text {
      color: $red;
    }
  }
  button:hover{
    background: transparent;
  }
}

.number-circle-style{
  width: 32px;
  height: 32px;
  margin-right: 8px;
  background: $main;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal 600 16px/22px Open Sans;
  color: $white;
}

.line-style{
  border-right: 1px dotted $lightGray;
  margin-right: 24px;
  margin-left: 15px;
}

.toast-wrapper{
  display: flex;
  align-items: center;

  img{
    margin-right: 16px;
  }

  p{
    font-size: 16px;
    color: $text;
  }
}

.message-mini-screen {
  width: 345px;
  height: 78px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 12px #0000001A;
  border-radius: 8px;
  display: flex;
  padding: 16px;
  align-items: center;
  position: absolute;
  bottom: 50px;
  justify-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  right: 20px;

  p{
    color: $text;
    font-size: 16px;
    line-height: 24px;
    margin-left: 16px;
  }
}