/*TextToolTip*/
.text-tooltip-wrapper {
  .main-tooltip-text {
    width: 100%;
    color: #51566D;
    font-family: "Open Sans",sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    display: inline-block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
    overflow-wrap: break-word;
    -ms-word-break: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
}