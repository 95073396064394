@import 'src/assets/styles/globalStyles';

.no-info-wrapper {
    position: absolute;
    display: flex !important;
    width: 93%;

    & .MuiTableCell-root {
        border: none;
        font-size: 18px;
        font-weight: bold;
    }
    & .MuiTableCell-sizeSmall {
        padding: 50px 0 26px 0;
        border: none;
        font-size: 18px;
        font-weight: bold;
    }
}

.no-info-text {
    border: none;
    font-size: 16px;
    line-height: 24px;
    color: $lightGray;
    font-weight: bold;
}

.pagination-wrapper {
    width: 100%;
    margin: 16px;
    display: flex;
    justify-content: flex-end;
    padding: 0 32px;

    .MuiPaginationItem-root.Mui-selected {
        background: $main;
        border-color: $main;
        color: $white;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
    }

    .MuiPaginationItem-root.Mui-selected:hover {
        background: $main;
        border-color: $main;
        color: $white;
    }

    .MuiPaginationItem-outlined {
        background: $white;
        border: 1px solid #9698a7;
        border-radius: 4px;
        text-align: center;
        font-weight: 600;
        font-size: 14px;
        color: #6a6d84;
    }

    .MuiPaginationItem-outlined:hover {
        background: #ecf1fa 0% 0% no-repeat padding-box;
        border: 1px solid #9698a7;
    }
}

.table-wrapper {
    height: 100%;
    min-height: 80vh;
    background: $white;
    width: 100%;
    border-radius: 8px;
    margin-top: 25px;
    margin-bottom: 24px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .MuiTableContainer-root {
        box-shadow: none;
    }
}

.status-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}
