@import "src/assets/styles/globalStyles";

.list-amazon {
  margin-top: 16px;
  background-color: $white;
  padding: 16px;

  .input-box {
    width: 50%;

    .boxFlex {
      display: flex;
      flex: 1;

      .css-1sfnch9-MuiFormControl-root-MuiTextField-root {
        margin-bottom: 0;
        //padding-right: 20px;
      }

      .add-invite-button {
        padding: 14px 32px;
      }
    }
  }
}

.type-list {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  height: 550px;
  overflow: auto;

  .list-card {
    margin: 3px 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    background-color: $darkWhite;
    border-radius: 8px;
    padding: 14px 16px;

    .list-action {
      columns: 2;
      column-gap: 10px;
      display: flex;
      justify-content: space-between;

      .css-e784if-MuiTypography-root {
        font-size: 14px;
        font-weight: 600;
        color: $darkText;
      }
    }
  }
}

.css-14iuebz-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.6);
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.6);
}

.css-14iuebz-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.6);
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.6);
}

.mx-2 {
  margin: 0 10px;
}

.product-map-modal {
  background: white;
  width: 335px;
  min-width: 335px;
  margin-top: 24px;
  margin-left: 16px;
  height: 70vh;
  border-radius: 8px;

  .product-map-modal-head {
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #2A374E;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    border-bottom: 1px solid #E7EFFB;
  }

  .product-map-modal-body {
    padding: 16px 24px;
  }

  .product-map-item-body {
    max-height: 60vh;
    overflow: auto;
  }

  .product-map-box {
    display: flex;
    align-items: center;
    color: #2A374E;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 12px;
    padding: 0 16px;

    p:first-child {
      width: 180px;
    }
  }

  .product-map-item-box {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border-radius: 4px;
    background: #F7F9FC;
    width: 100%;
    margin-bottom: 6px;
    color: #2A374E;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    p:first-child {
      width: 180px;
    }
  }
}

.shopify-map-input-button-wrapper {
  display: flex;
  align-items: center;
  gap: 50px;
  justify-content: space-between;

  .search-erply-input {
    max-width: 544px;
  }
}