$white: #ffffff;
$black: #000;
$darkWhite: #f7f9fc;
$main: #ba43b1;
$darkMain: #901f89;
$lightMain: #f4e5f3;
$text: #2a374e;
$text1: #9C9EA9;
$lightText: #9698a8;
$disabledInput: #A6ADB8;
$placeHolder: #6a6d84;
$primary: #51566d;
$red: #d72525;
$border: #51566db3;
$lightGray: #51566d80;
$closeGray: #e6ecf3;
$chancelButton: #e6ecf380;
$backgroundColor1: #FEF3E1;
$backgroundColor2: #EB7104;
$mainOrange: #EF6D00;

$small: 767px;

::-webkit-scrollbar {
    display: none;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #ffffff inset;
    color: #545f7e;
}

// Global styles

.space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.flex {
    display: flex;
    align-items: center;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flex-end {
    width: 100%;
    justify-content: flex-end;
    display: flex;
}

.brd {
    border: 1px solid red;
}

// Global styles End

.login-title {
    font: normal normal bold 32px/48px Open Sans;
    color: $text;
}

.login-text {
    font: normal normal normal 16px/24px Open Sans;
    color: $placeHolder;
}

.tableContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 110vh;

    @media (min-width: 1919px) {
        height: 85vh;
    }
}

.title-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    //.tabBox {
    //  width: 100%;
    //  background-color: $white;
    //  padding: 4px;
    //  border-radius: 8px;
    //}
}

.buttons-wrapper {
    display: flex;
}

.add-button-wrapper {
    width: auto;
    display: flex;
    justify-content: flex-end;
}

.text-error {
    color: #d72525;
}

.text-success {
    color: #0bb53a;
}

.MuiInput-underline.Mui-disabled:before{
    border-bottom-style: solid!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.transfer-successful{
    display: flex;
    width: 119px;
    padding: 4px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #E7F8EB;
    color: #0DA137;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}

.transfer-error{
    display: flex;
    width: 119px;
    padding: 4px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    background: #FBF0F0;
    color: #D72525;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}

.fully-successful{
    border-radius: 2px;
    background: rgba(11, 181, 58, 0.10);
    padding: 4px 16px;
    color: #0DA137;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    width: 160px;
    text-align: center;
}

.partial-successful{
    border-radius: 2px;
    background: #FFF7E8;
    padding: 4px 16px;
    color: #FFA710;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    width: 160px;
    text-align: center;
}

.unsuccessful{
    border-radius: 2px;
    background: #FBF0F0;
    padding: 4px 16px;
    color: #D72525;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 160px;
    text-align: center;
}
.failure{
    color: #D72525;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}
.successful{
    color: #0DA137;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}
.resync-sync-btn{

    button {
        border: none;
        background: transparent;
        text-decoration: underline;
        color: #6A6D84;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
}