$white: #FFFFFF;
$darkWhite: #F7F9FC;
$main: #BA43B1;
$darkMain: #901F89;
$lightMain: #F4E5F3;
$text: #2A374E;
$lightText: #9698A8;
$placeHolder: #6A6D84;
$primary: #51566D;
$red: #D72525;
$border: #51566DB3;
$lightGray: #51566D80;
$closeGray: #E6ECF3;
$chancelButton: #E6ECF380;
$darkText: #4B5C68;