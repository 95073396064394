@import "src/assets/styles/globalStyles";

.add-modal-fragment {
  width: 514px;
  background: $white;
  border-radius: 8px;
  z-index: 9999;
  position: relative;
}

.add-modal-fragment-2 {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 514px;
  background: $white;
  border-radius: 8px;
  z-index: 9999;
  position: relative;
}

.delete-modal-fragment {
  width: 530px;
  height: auto;
  background: $white;
  border-radius: 8px;
  z-index: 9999;
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 8px;
}

.drawer-settings-order {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;

  .drawer-settings-order-title {
    color: $black;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
  }
}

.modal-body {
  padding: 0 32px 32px 32px;
}

.modal-body-2 {
  padding: 0 32px 32px 32px;
  height: 100%;
}

.add-modal-title {
  font: normal normal bold 32px/43px Open Sans;
  color: $text;
}

.add-modal-description {
  color:$placeHolder;
  text-align: center;
  font-family: "Open Sans",sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;
}

.add-erply-code {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 24px;

  img {
    margin-right: 12px;
  }

  button {
    border: none;
    background: transparent;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $main;
  }
}

.add-modal-subtitle {
  font: normal normal normal 16px/24px Open Sans;
  color: $primary;
  margin: 16px 0 32px 0;
}

.delete-modal-buttons {
  display: flex;
  margin-top: 30px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: none;
  }

  button:first-of-type {
    margin-right: 16px;
  }
}

.delete-button {
  background: $red;
  font: normal normal 600 16px/22px Open Sans;
  color: $white;
}

.cancel-button {
  background: $chancelButton;
  font: normal normal 600 16px/22px Open Sans;
  color: $primary;
}

.list-wrapper {
  display: flex;
  flex-direction: column;
  width: 488px;
  height: 100%;
  z-index: 1150
}

.list-wrapper-head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 28px;
  position: sticky;
  top: 0;
  right: 0;
  padding: 48px 48px 0;
  background: white;
  z-index: 2;

  .close-button {
    margin-left: auto;
  }
}

.list-wrapper-body {
  height: 100%;
  padding: 0 48px 48px;
  margin-top: 48px;
}

.list-title {
  color: $black;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
}

.list-buttons-wrapper {
  display: flex;
  margin-top: 10px;

  button {
    width: 100%;
    height: 48px;
    font: normal normal 600 16px/22px Open Sans;
    border-radius: 8px;
  }

  button:first-of-type {
    margin-right: 15.5px;
  }

  .chancel-button {
    border: 1px solid $main;
    color: $main;
    background: transparent;
  }

  .chancel-button:hover {
    background: $main;
    color: $white;
    border: 1px solid transparent;
  }

  .save-button {
    color: $white;
    background: $main;
    border: none;
  }

  .save-button:hover {
    background: $darkMain;
  }
}

.credentials-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .css-1sfnch9-MuiFormControl-root-MuiTextField-root {
    margin-bottom: 32px;
  }
}

.credentials-swicher {
  display: flex;
  justify-content: flex-end;

  .css-ahj2mt-MuiTypography-root {
    font-size: 14px;
    font-weight: 600;
    color: $placeHolder;
  }

  .css-julti5-MuiSwitch-root {
    padding: 12px 10px;
  }
}

.switch-colors {
  color: $main;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background-color: $lightMain;
  color: $main;
  border-radius: 8px;
}

.MuiTabs-root {
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #BA43B1;
  }

  .css-1aquho2-MuiTabs-indicator {
    background-color: transparent;
  }

  .MuiButtonBase-root {
    padding: 8px 24px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 700;
  }
}

.ebay-connected-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  padding: 16px;
  border-radius: 8px;
  background: $backgroundColor1;

  .ebay-connected-text {
    color: $black;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .ebay-connect-button {
    width: 113px;
    height: 36px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    border-radius: 8px;

    &.connect {
      color: $white;
      background: $backgroundColor2;
      border: 1px solid $mainOrange;
    }

    &.disconnect {
      color: $mainOrange;
      border: 1px solid $mainOrange;
      background: transparent;
    }
  }
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: $text;
}

.edit-type {
  background-color: $white;
}

.modal-form {
  margin-top: 40px;
}

.row-for-erply {
  height: auto;
  background: #FAFAFA;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
}

.close-erply-code {
  background: transparent;
  border: none;
}

.add-save-button {

}

.MuiModal-root {
  z-index: 9999999 !important;
}

.add-edit-store-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}