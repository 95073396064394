@import 'src/assets/styles/globalStyles';

.accord-craft{
    & .MuiPaper-root {
        height: auto;
    }
}

.carecraft-container {
    .carecraft-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
    }
}

.acordion-container {

    .MuiAccordionSummary-root{
        padding: 0 16px 0 0;
    }
    .MuiAccordion-root.Mui-expanded{
        margin: 0;
    }

    .accordion-tr {
        display: flex;
        align-items: center;
        width: 100%;
        //user-select: text;
        .min-load {
            margin-left: 50px;
        }
        .accordion-th-big {
            width: 34%;
            padding: 16px 18px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #51566d;
            user-select: text;
        }
        .accordion-th {
            width: 17%;
            padding: 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #51566d;
            user-select: text;
        }
        .partial-success {
            background: rgba(11, 181, 58, 0.1);
            border-radius: 2px;
            padding: 4px 10px;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #0da137;
            text-align: center;
        }
        .partial-worring {
            background: #fff7e8;
            border-radius: 2px;
            border-radius: 2px;
            padding: 4px 10px;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #ffaa04;
            text-align: center;
        }
        .partial-error {
            background: #fbf0f0;
            border-radius: 2px;
            padding: 4px 24px;
            border-radius: 2px;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #db6c6c;
            text-align: center;
        }
        .btn-resent {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-decoration-line: underline;
            margin-left: 15px;
            color: #6a6d84;
            border: none;
            background-color: inherit;
        }
        .btn-delete {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin-left: 15px;
            color: #d03325;
            border: none;
            background-color: inherit;
        }
    }
    .header-status {
        //margin-left: 25px;
    }
    .accordion-body {
        padding: 16px;
        .banner {
            margin-bottom: 15px;
            .titile-banner {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #506c84;
            }
        }
        .table-header {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 12px;
        }

        .body-list {
            display: flex;
            align-items: center;
            width: 100%;
            background: #f7f9fc;
            padding: 12px;
        }
        .table-th {
            color: #506c84;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
        }
        .body-th {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #506c84;
        }

        .th-sku {
            width: 20%;
        }
        .th-quantity {
            width: 60%;
        }
        .th-status {
            width: 20%;
        }
        .see-details {
          text-decoration: underline;
        }

        .table-body {
            padding-top: 15px;
        }
    }
}

.accordion-container {
    margin-top: 15px;
}

.list-amazon {
    margin-top: 16px;
    background-color: $white;
    padding: 16px;

    .input-box {
        width: 50%;

        .boxFlex {
            display: flex;
            flex: 1;

            .css-1sfnch9-MuiFormControl-root-MuiTextField-root {
                margin-bottom: 0;
            }

            .add-invite-button {
                padding: 14px 32px;
            }
        }
    }
}

.type-list {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    height: 550px;
    overflow: auto;

    .list-card {
        margin: 3px 0;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        background-color: $darkWhite;
        border-radius: 8px;
        padding: 14px 16px;

        .list-action {
            columns: 2;
            column-gap: 10px;
            display: flex;
            justify-content: space-between;
            .css-e784if-MuiTypography-root {
                font-size: 14px;
                font-weight: 600;
                color: $darkText;
            }
        }
    }
}

.css-14iuebz-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.6);
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: rgba(0, 0, 0, 0.6);
}

.css-14iuebz-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.6);
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: rgba(0, 0, 0, 0.6);
}

.mx-2 {
    margin: 0 10px;
}
