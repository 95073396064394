@import "src/assets/styles/globalStyles";

.login-page {
  background-repeat: no-repeat;
  background-size: 100%;
  overflow: hidden;
  height: 100vh;
  object-fit: cover;

  .login-head {
    height: 60px;
    background: #000000 0 0 no-repeat padding-box;
    border-bottom: 1px solid $white;

    display: flex;
    align-items: center;
    div{
      margin: 0 auto;
      padding: 0 141px;
      max-width: 1440px;
      width: 100%;
      cursor: pointer;
    }
  }

  .login-body {
    max-width: 1440px;
    padding: 0 141px;
    display: flex;
    justify-content: space-between;
    margin: 0px auto;
    position: relative;
    top: 20%;

    .about-text {
      font-weight: 800;
      font-size: 40px;
      line-height: 64px;
      font-family: Montserrat, serif;
      margin-top: 60px;
      letter-spacing: 2.4px;
      color: $white;
    }
  }

  .copy-right-wrapper {
    position: absolute;
    bottom: 24px;
    padding: 0 141px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    max-width: 1440px;
    width: 100%;

    .copy-right {
      font: normal normal normal 14px/19px Open Sans;
      color: $white;

    }
  }

  .modal-styles {
    width: 464px;
    height: auto;
    background: white;
    border-radius: 8px;
    padding: 32px;
  }

  .login-modal-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .login-modal-forgot {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    color: $lightText;
    background: none;
    border: none;
    outline: none;
    width: 200px;
  }

  .message-screen-wrapper {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    justify-content: center;
    display: flex;
  }

  .message-screen-modal {
    background: $white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 332px;
    height: auto;
    padding: 32px;
    margin-top: 70px;
  }

  .message-screen-response {
    font: normal normal normal 16px/24px Open Sans;
    color: $placeHolder;
  }

  .message-screen-text {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 32px;
    font-weight: bold;
    color: $text,
  }

  .forgot-button {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    background: none;
    border: none;
    outline: none;
    width: 200px
  }
}