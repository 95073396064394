@import "src/assets/styles/globalStyles";

.err-message-position {
  display: flex;
  width: 100%;
  margin: 10px 0 10px 0;
}

.err-message-text {
  color: $red;
  margin: 5px 0;
  font: normal normal normal 12px/17px Open Sans;
}