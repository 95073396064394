@import "src/assets/styles/globalStyles";

.add-invite-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  padding: 9px 24px;
  background: $main;
  border-radius: 8px;
  border: none;
}

.add-invite-button:hover {
  background: $darkMain;
  box-shadow: 0 2px 4px #7B1E83;
}

.add-invite-button-text {
  font: normal normal 600 14px/20px Open Sans;
  margin-left: 8px;
  color: $white;
}

.settings-button {
  display: flex;
  align-items: center;
  padding: 9px 24px;
  background: transparent;
  border: 1px solid $main;
  border-radius: 8px;
  margin-left: 20px;
}

.settings-button:hover {
  background: $main;
  border: 1px solid transparent;

  p {
    color: white;
  }

  img {
    filter: brightness(0) invert(1);
  }
}

.settings-button-text {
  font: normal normal 600 14px/20px Open Sans;
  margin-left: 8px;
  color: $main;
}

.close-button {
  width: 24px;
  height: 24px;
  background: $closeGray;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.add-modal-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background: $main;
  border-radius: 8px;
  border: none;
  width: 100%;
  color: $white;
  font: normal normal 600 16px/22px Open Sans;

  span {
    margin-left: 5px;
  }
}

.add-modal-button:hover {
  background: $darkMain;
  box-shadow: 0 2px 4px #7B1E83;
}