@import "src/assets/styles/globalStyles";

.input-wrapper {
  width: 100%;

  & .MuiFormLabel-root {
    font-size: 16px;
    color: $primary;
  }

  & .MuiInput-underline.Mui-error:after {
    border-bottom-color: $red
  }
}

.input-text-field {
  width: 100%;

  & .MuiOutlinedInput-notchedOutline {
    border-color: $border
  }

  & .MuiOutlinedInput-root {
    height: 48px;
  }

  & .MuiInputLabel-outlined {
    margin-top: -3px;
  }

  & .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -2px) scale(0.75);
  }

  & .MuiSelect-select.MuiSelect-select {
    background: transparent;
  }
}

.password-input {
  & .MuiInputBase-input::-webkit-input-placeholder {
    font-size: 16px;
    color: $primary;
    opacity: 1;
  }
}

.search-input-title {
  display: flex;
  justify-content: space-between;

  & p {
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    color: $text;
    margin-right: 4px;
  }

  img {
    width: 18px;
    height: 18px;
    cursor: pointer;
    margin-left: 6px;
  }
}

.search-input {
  width: 100%;
  margin-bottom: 8px;

  & .MuiFormLabel-root {
    font-size: 16px;
    color: $primary;
  }

  & .MuiInput-underline.Mui-error:after {
    border-bottom-color: $red;
  }
}

.action-style {
  width: 100%;
  margin-bottom: 8px;

  & .MuiInput-underline:before {
    //border-bottom: none;
  }
}

//InputMain

.main-input-wrapper {
  width: 100%;
  position: relative;


  .MuiInputAdornment-root {
    width: 24px;
    cursor: pointer;
    height: fit-content;
    display: flex;
    justify-content: center;


    &.error-svg {
      svg {
        path {
          stroke: $red;
        }
      }
    }

    &.error-svg-password {
      svg {

        path {
          stroke: $red;
        }
      }
    }
  }

  .MuiFormControl-root {
    width: 100%;
    height: 48px;

    .MuiInputLabel-outlined {
      transform: translate(14px, 16px) scale(1);

      &.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75);
        color: $main;

        &.Mui-error {
          color: $red;
        }
      }

      &.Mui-disabled {
        color: $disabledInput;
      }
    }

    &:hover {
      .MuiInputLabel-outlined {
        &.MuiInputLabel-shrink {
          &.Mui-error {
            color: $red;
          }
        }
      }
    }

    .MuiInputBase-root {
      display: flex;
      align-items: center;
      height: 100%;
    }

    input {
      padding: 13px 16px;
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
    }


    .Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid $red;
      }
    }
  }

  .MuiInputBase-root {
    border-radius: 8px;

    &:hover {
      .MuiInputAdornment-positionStart {
        svg {

        }
      }

      .MuiOutlinedInput-notchedOutline {
        border: 1px solid $main;
      }

      &.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
        border: 1px solid $red;
      }
    }

    &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 2px solid $main;
    }

    &.MuiOutlinedInput-root.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 2px solid $red;
    }

    &.Mui-disabled {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid $disabledInput;
      }
    }

  }

  input {
    padding: 0;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid $main;
  }

  .Mui-error {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid $red;
    }
  }

  .Mui-focused {
    .MuiInputAdornment-positionStart {
      svg {
        path {
        }
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid $main;
    }
  }


  .MuiInputAdornment-root ~ input {
    padding-left: 0;
  }

  &.search-erply-input {
    .MuiInputBase-root {
      background: $white;

      .MuiOutlinedInput-notchedOutline {
        border: none;
      }

      &.Mui-focused {
        border: none;

        .MuiOutlinedInput-notchedOutline {
          border: none;
        }
      }
    }
  }
}