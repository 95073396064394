@import 'src/assets/styles/globalStyles';

.menu-bar-wrapper {
  display: flex;

  .header-wrapper {
    padding: 18px 16px;
    background: $white;
    box-shadow: 0 3px 6px #8a8a8a29;
    position: fixed;
    z-index: 1300;
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: space-between;
  }

  .header-logo-breadcrumb{
    display: flex;
    align-items: center;
    transition: transform 0.2s
  }

  .create-by {
    position: absolute;
    bottom: 30px;
    left: 16px;
  }

  .logo {
    font: normal normal 900 19px/25px Roboto;
    letter-spacing: 0;
    color: $main;
  }

  .MuiDrawer-paper {
    width: 200px;
    background: $white;
    border: none;
  }

  .body-wrapper {
    background: $darkWhite;
    height: 100%;
    min-height: 100vh;
    padding-left: 35px;
  }

  .items-wrapper {
    height: 36px;
    justify-content: center;
  }

  .icon-button {
    position: fixed;
    z-index: 1440;
    height: 36px;
    width: 36px;
    margin-top: 60px;
    margin-left: -15px;
    border-radius: 8px;
    background: $white;
    border: 1px solid #51566d80;
    color: $main;
  }

  .items-wrapper:hover {
    background: $white;
    border-radius: 8px;
  }

  .items-wrapper-active {
    height: 36px;
    background: $lightMain;
    border-radius: 8px;
    justify-content: center;
  }

  .list-item-icon {
    min-width: 0;
    justify-content: center;
  }

  .list-item-text {
    font: normal normal 600 14px/19px Open Sans;
    letter-spacing: 0;
    color: $placeHolder;
    margin-top: 7px;
  }

  .list-item-sub-arrow {
    filter: brightness(0) saturate(100%) invert(44%) sepia(5%) saturate(1434%) hue-rotate(197deg) brightness(96%) contrast(96%);
    transform: rotate(180deg);

    &.list-item-sub-arrow_active{
      filter: brightness(0) saturate(100%) invert(34%) sepia(58%) saturate(1072%) hue-rotate(263deg) brightness(98%) contrast(95%);
    }
  }

  .list-style {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    margin-top: 80px;
    padding: 16px;
    left: 0;

    .list-style-box {
      .sub-list-style-wrapper {
        display: flex;
        flex-direction: column;
        gap: 6px;
        padding: 12px 8px;

        .sub-list-item-style {
          color: $placeHolder;
          font-family: "Open Sans", sans-serif;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          text-align: start;

          padding: 8px;
          border: none;
          outline: none;
          background: transparent;
          border-radius: 8px;

          &.sub-list-item-style-active {
            background: $lightMain;
            color: $main;
          }
        }
      }
    }
  }

  .user-info {
    display: flex;
    align-items: center;
  }

  .user-info-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      width: 40px;
      height: 40px;
      border: 1px solid $white;
      border-radius: 24px;
    }
  }
}
