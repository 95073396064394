@import "../../assets/styles/globalStyles";

.breadcrumb-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;

  a {
    display: flex;
    align-items: center;
    gap: 8px;

    color: $text1;
    font-family: "Open Sans",sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-decoration: none;
  }

  p {
    color: $text;
    font-family: "Open Sans",sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
}